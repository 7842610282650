import React from "react";
import '../styles/views/ComprasView.css';
import ComprasListComponent from "../components/ComprasListComponent";
import { VentasProvider } from "../contexts/VentasContext";
import ProductosListComponent from "../components/ProductosListComponent";
import { ProductosProvider } from "../contexts/ProductosContext";


const ComprasView = () => {

    // const compra = React.useContext(CompraContext);

    return (
        <div className="ComprasView">
            <VentasProvider>
                <ProductosProvider>
                    <ProductosListComponent />
                </ProductosProvider>
                <ComprasListComponent />
            </VentasProvider>
        </div>
    );
};




export default ComprasView;