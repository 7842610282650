import React from "react";
import CustomerRelationManager from "../pages/CustomerRelationManager.jsx";
import DemosView from "../pages/DemosView.jsx";
import DemotecaView from "../pages/DemotecaView.jsx";
import LocutoresView from "../pages/LocutoresView.jsx";
import WelcomeView from "../pages/WelcomeView.jsx";
import CheckLogin from "../components/CheckLogin.jsx";
import ReelsView from "../pages/ReelsView.jsx";
import AudiosView from "../pages/AudiosView.jsx";
import CastingView from "../pages/CastingView.jsx";
import ComprasView from "../pages/ComprasView.jsx";
import { CompraProvider } from "../contexts/CompraContext.jsx";
import TarifarioView from "../pages/TarifarioView.jsx";
import DescuentoView from "../pages/DescuentoView.jsx";
import AyudaView from "../pages/AyudaView.jsx";
// import CRMView from "../pages/CRMView.jsx";

function Activity({ activity, setActivity, setIsAuth, goto = "welcome" }) {

    return (
        <CompraProvider>
            <div className="Activity">
                {activity == null && <CheckLogin setActivity={setActivity} setIsAuth={setIsAuth} goto={goto} />}
                {activity === "loading" && <p>Loading...</p>}
                {activity === "welcome" && <WelcomeView setActivity={setActivity} />}
                {activity === "locutores" && <LocutoresView setActivity={setActivity} />}
                {activity === "demos" && <DemosView setActivity={setActivity} />}
                {activity === "record" && <DemotecaView setActivity={setActivity} />}
                {activity === "onerecord" && <AudiosView setActivity={setActivity} />}
                {/* {activity === "crm" && <CRMView setActivity={setActivity}/>} */}
                {activity === "crm" && <CustomerRelationManager setActivity={setActivity} />}
                {activity === "reels" && <ReelsView setActivity={setActivity} />}
                {activity === "casting" && <CastingView setActivity={setActivity} />}
                {activity === "compras" && <ComprasView setActivity={setActivity} />}
                {activity === "tarifario" && <TarifarioView setActivity={setActivity} />}
                {activity === "descuento" && <DescuentoView setActivity={setActivity} />}
                {activity === "ayuda" && <AyudaView setActivity={setActivity} />}
            </div>
        </CompraProvider>
    );
}

export default Activity;