import React from "react";

import '../styles/views/DescuentoView.css';
import { serviceFetch, serviceHttpRequest } from "../utils/serviceFetch";
// import { VentasProvider } from "../contexts/VentasContext";
import { useFetch } from "../hooks/useFetch";
import { useParams } from "react-router-dom";
import PayPalElement from "../components/PayPalElement";

const DescuentoView = () => {

    const { codigo } = useParams();
    const [mensaje, setMensaje] = React.useState(null);

    const { data: descuento } = useFetch(`v1/descuento/${codigo}`, {}, []);

    const [venta] = React.useState(null);

    const handlePaypalOnCancel = (data) => {
    };

    const handlePaypalOnApprove = (data, actions) => {
        let pago = {
            fecha: new Date().toISOString(),
            monto: venta.total,
            json: JSON.stringify(data),
            texto: "Compra de productos con Paypal",
            venta: venta.id,
            medio_de_pago: "paypal"
        };

        serviceFetch(`v1/pagos/checkout-order/${data['orderID']}/`)
            .then(r => r.json())
            .then(json => {
                if (json.status === "APPROVED") {
                    serviceHttpRequest("v1/pagos/pago-paypal/", { "method": "POST", "body": JSON.stringify(pago) }, null, null, "application/json")
                        .then(json => {
                            console.log(json);
                            alert("Pago aprobado");
                        });
                }
            })
            .error(e => {
                console.log(e);
            });


    };

    const handleFlowPayment = () => {
        setMensaje("Preparando compra");
        const body = {
            "fecha": new Date().toISOString(),
            "total": descuento.producto.precio,
            "medio_de_pago": "flow",
            "estado": "pendiente",
            "glosa": `${descuento.producto.nombre} / -${descuento.porcentaje}%`,
            "codigo": `C${new Date().getTime()}`,
            "detalles": [
                {
                    "producto": descuento.producto.id,
                    "cantidad": 1,
                    "precio": descuento.producto.precio,
                    "moneda": descuento.producto.moneda.id
                },
            ],
            "moneda": descuento.producto.moneda.id
        };

        serviceHttpRequest("v1/ventas/", { "method": "POST", "body": JSON.stringify(body), }, null, null, "application/json")
            .then(venta => {
                setMensaje("Preparando Pago");
                serviceFetch(`v1/ventas/suscripcion-flow/${venta.id}/`)
                    .then(r => r.json())
                    .then(json => {
                        if (json.status === "ok") {
                            setMensaje("Suscripción lista para pago. Revise su correo para completar el pago");
                            console.log(json);
                        } else {
                            alert("Error al solicitar el pago");
                            console.log(json);
                        }
                    });
            });


    };
    return (
        <div className="DescuentoView">
            <h1>¡Oferta Imperdible!</h1>

            <div className="DescuentoViewContent">
                {descuento && <>
                    <div className="Resumen">
                        <h2>Total</h2>
                        <h1>{descuento.producto.precio} {descuento.producto.moneda.nombre}</h1>
                        <p>Aviso: Para asegurar el proceso de pago, es necesario que tu correo electrónico funcione correctamente.</p>
                        {mensaje && <div className="Mensaje">{mensaje}</div>}
                        {!mensaje && <div className="MediosDePago">
                            {descuento.paypal_enabled && <div><PayPalElement plan_id={descuento.producto.paypal_id} onApprove={handlePaypalOnApprove} onCancel={handlePaypalOnCancel} /></div>}
                            {descuento.flow_enabled && <div><img onClick={handleFlowPayment} width="400" alt="Flow" src="/logo-flow.svg" /></div>}
                        </div>}
                    </div>

                    <div>
                        <h2>{descuento.producto.nombre}</h2>
                        <p>{descuento.producto.descripcion}</p>
                        <div>Descuento: -{Math.floor(descuento.porcentaje)}%</div>
                        <div>{descuento.condicion}</div>
                    </div>
                </>}
            </div>
        </div>
    );
};

export default DescuentoView;