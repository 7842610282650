//import logo from './logo.svg';
import './App.css';
import MenuTopContainer from './containers/MenuTopContainer.jsx';
import MenuLeftContainer from './containers/MenuLeftContainer.jsx';
import Activity from './containers/Activity.jsx';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PublicProfileView from './pages/PublicProfileView.jsx';

function App() {


  // const [path, setPath] = useState(window.location.pathname);

  return (
    <div className="GoDemos">
      <header>
        <MenuTopContainer />
      </header>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/welcome" element={<GoDemos goto_activity="welcome" />} />
            <Route path="/casting" element={<GoDemos goto_activity="casting" />} />
            <Route path="/record" element={<GoDemos goto_activity="record" />} />
            <Route path="/crm" element={<GoDemos goto_activity="crm" />} />
            <Route path="/billing_subscription" element={<div>Billing subscription</div>} />
            <Route path="/compras" element={<GoDemos goto_activity='compras' />} />
            <Route path="/profile/:username/" element={<PublicProfileView/>} />
            <Route path="/tarifario" element={<GoDemos goto_activity='tarifario'/>} />
            <Route path="/descuento/:codigo" element={<GoDemos goto_activity='descuento'/>} />
            <Route path="/ayuda" element={<GoDemos goto_activity='ayuda'/>} />
            <Route path="*" element={<GoDemos />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>

  );
};

const GoDemos = ({ goto_activity = "welcome" }) => {
  const [activity, setActivity] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  return (
    <>
      {isAuth && <MenuLeftContainer isAuth={isAuth} activity={activity} setActivity={setActivity} />}
      <Activity activity={activity} setActivity={setActivity} setIsAuth={setIsAuth} goto={goto_activity} />
    </>
  );
}

export default App;
