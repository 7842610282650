import React from "react";

export const CompraContext = React.createContext();

export const CompraProvider = ({ children }) => {
    const compraDefault = {
        "id": null,
        "fecha": null,
        "codigo": null,
        "total": 0,
        "moneda": {
            "id": 1,
            "nombre": "USD",
            "tasa_cambio_dolar": "1.00",
            "created": "2024-12-16T19:40:52.393300Z"
        },
        "estado": "carrito",
        "estado_name": "Carrito de Compras",
        "medio_de_pago": null,
        "medio_de_pago_name": null
    };
    const [compra, setCompra] = React.useState(compraDefault);
    const [show, setShow] = React.useState(false);
    const [detalle, setDetalle] = React.useState([]);
    const [showPago, setShowPago] = React.useState(false);

    const resetCompra = () => {
        setCompra(compraDefault);
        setDetalle([]);
    };

    const addProducto = (producto) => {

        compra.total += Math.round(producto.precio, 2);
        if (detalle.find(i => i.producto.id === producto.id)) {
            detalle.forEach(i => {
                if (i.producto.id === producto.id) {
                    i.cantidad += 1;
                }
            });
            setDetalle([...detalle]);
            return;
        }

        let item = {
            "id": null,
            "producto": producto,
            "moneda": producto.moneda,
            "cantidad": 1,
            "precio": producto.precio,
            "venta": null
        };

        setDetalle([...detalle, item]);
    }

    const removeProducto = (producto) => {
        compra.total -= Math.round(producto.precio, 2);
        if (detalle.find(i => i.producto.id === producto.id)) {
            detalle.forEach(i => {
                if (i.producto.id === producto.id) {
                    i.cantidad -= 1;
                }
            });

            setDetalle(detalle.filter(i => i.cantidad > 0));
            return;
        }
    }

    return (
        <CompraContext.Provider value={{ compra, detalle, show, showPago, resetCompra, setShowPago, setShow, setCompra, addProducto, removeProducto }}>
            {children}
        </CompraContext.Provider>
    );
}