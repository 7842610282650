import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";

export const PublicProfileContext = React.createContext();

export const PublicProfileProvider = ({ children }) => {

    const { username } = useParams();

    const { loading: profileLoading, data: profileData, error: profileError } = useFetch(`public/profile/get/${username}`);
    const { loading: demosLoading, data: demosData, error: demosError } = useFetch(`public/profile/demoteca/${username}`);
    const { loading: misDemosLoading, data: misDemosData, error: misDemosError } = useFetch(`public/profile/mis-demos/${username}`);

    const [tipos, setTipos] = React.useState([]);
    const [edades, setEdades] = React.useState([]);
    const [emociones, setEmociones] = React.useState([]);

    const [demo, setDemo] = React.useState(null);

    useEffect(() => {
        if (!demosData) return;

        setTipos(demosData.configuracion.tipoCollection);
        setEdades(demosData.configuracion.edadCollection);
        setEmociones(demosData.configuracion.emocionCollection);

    }, [demosData]);

    return (
        <PublicProfileContext.Provider value={{ username, demo, setDemo, profileLoading, tipos, edades, emociones, profileData, profileError, demosLoading, demosData, demosError, misDemosData, misDemosLoading, misDemosError }}>
            {children}
        </PublicProfileContext.Provider>
    );
};