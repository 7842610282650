import React from "react";
import { DetalleVentaContext, DetalleVentaProvider, VentasContext } from "../contexts/VentasContext";
import { CloseIcon, FileDocIcon } from "./BootstrapIcons";
import { CompraContext } from "../contexts/CompraContext";
import ModalContainer from "../containers/ModalContainer";
import PayPalElement from "./PayPalElement";
import { serviceFetch, serviceHttpRequest } from "../utils/serviceFetch";

const ComprasListComponent = () => {

    const { ventas, loading, error, venta, setVenta } = React.useContext(VentasContext);

    return (
        <>
            <h1>Compras</h1>
            <div className="ComprasList">
                {loading && <p>Cargando historial de compras...</p>}
                {error && <p>Error...{console.log(error)}</p>}
                {ventas && <>
                    <div>
                        <div>Glosa</div>
                        <div>Estado</div>
                        <div>Total</div>
                        <div>Moneda</div>
                        <div>Medio de Pago</div>
                        <div>Fecha</div>
                    </div>
                    {ventas.map((venta) => (
                        <div key={venta.id}>
                            <div>{venta.glosa}</div>
                            <div>{venta.estado_name}</div>
                            <div>{venta.total?.toLocaleString()}</div>
                            <div>{venta.moneda?.nombre}</div>
                            <div>{venta.medio_de_pago_name}</div>
                            <div>{new Date(venta.fecha).toLocaleDateString()}</div>
                            <div><i onClick={e => setVenta(venta)}><FileDocIcon /></i></div>
                        </div>
                    ))}
                </>}
            </div>

            {venta &&
                <DetalleVentaProvider venta={venta}>
                    <CompraDetalle />
                </DetalleVentaProvider>
            }
        </>
    );
};

const CompraDetalle = ({ custom_detalle = null }) => {
    const [SELECCIONAR_PAGO, PROCESANDO_PAGO, PAGO_APROBADO] = [0, 1, 2];
    const [procesoCompra, setProcesoCompra] = React.useState(SELECCIONAR_PAGO);
    const { venta, setVenta, refreshVentas } = React.useContext(VentasContext);
    const { detalle, loading, error } = React.useContext(DetalleVentaContext);
    const { showPago, setShowPago } = React.useContext(CompraContext);

    const handleCancelarCompra = () => {
        serviceFetch(`v1/ventas/cancel-order/${venta.id}/`, "GET")
            .then(r => r.json())
            .then(json => {
                console.log(json);
                refreshVentas();
                setVenta(null);
            });
    };

    const handlePaypalOnCancel = (data) => {
        setProcesoCompra(SELECCIONAR_PAGO);
    };

    const handlePaypalOnApprove = (data, actions) => {
        setProcesoCompra(PROCESANDO_PAGO);
        let pago = {
            fecha: new Date().toISOString(),
            monto: venta.total,
            json: JSON.stringify(data),
            texto: "Compra de productos con Paypal",
            venta: venta.id,
            medio_de_pago: "paypal"
        };

        serviceFetch(`v1/pagos/checkout-order/${data['orderID']}/`)
            .then(r => r.json())
            .then(json => {
                if (json.status === "APPROVED") {
                    serviceHttpRequest("v1/pagos/pago-paypal/", { "method": "POST", "body": JSON.stringify(pago) }, null, null, "application/json")
                        .then(json => {
                            console.log(json);
                            refreshVentas();
                            setShowPago(false);
                            setVenta(null);
                            alert("Pago aprobado");
                        });
                }
            })
            .error(e => {
                console.log(e);
                setProcesoCompra(SELECCIONAR_PAGO);
            });


    };

    const handleFlowPayment = () => {
        setProcesoCompra(PROCESANDO_PAGO);
        serviceFetch(`v1/ventas/pago-flow/${venta.id}/`)
            .then(r => r.json())
            .then(json => {
                if(json.status==="ok")
                    window.location.href = json.url;
                else{
                    alert("Error al solicitar el pago");
                    console.log(json);
                }
            });
    };

    return (
        <>
            {venta && <div className="CompraDetalle">
                <div><i><CloseIcon onClick={e => setVenta(null)} /></i></div>
                <h2>Detalle de compra</h2>
                <div>{venta.estado_name}</div>

                <div>Medio de Pago: {venta.medio_de_pago_name}</div>
                <div>{venta.fecha && new Date(venta.fecha).toLocaleString()}</div>
                <h3>Detalle</h3>
                <div className="CompraDetalleList">
                    {loading && <p>Cargando detalle...</p>}
                    {error && <p>Error...{console.log(error)}</p>}
                    {detalle && detalle.map((item) => (
                        <div key={item.id}>
                            <div>{item.producto?.nombre}</div>
                            <div>{item.cantidad}</div>
                            <div>{item.precio?.toLocaleString()}</div>
                            <div>{item.moneda.nombre}</div>
                        </div>
                    ))}
                    <div>
                        <div></div>
                        <div>Total</div>
                        <div>{venta.total?.toLocaleString()}</div>
                        <div>{venta.moneda?.nombre}</div>
                    </div>
                </div>
                {venta.estado === "pendiente" &&
                    <div>
                        <button onClick={e => setShowPago(true)}>Pagar</button>&nbsp;
                        <button onClick={handleCancelarCompra}>Cancelar Compra</button>
                    </div>}
            </div>
            }
            {venta && showPago &&
                <ModalContainer setDisplay={e => {
                    setShowPago(false);
                    setVenta(null);
                    refreshVentas();
                }} display={true} color="black">
                    <div className="CompraPago">
                        <h2>Selecciona tu Medio de Pago</h2>
                        <p>Aviso: Para asegurar el proceso de pago, es necesario que tu correo electrónico funcione correctamente.</p>
                        {procesoCompra === SELECCIONAR_PAGO &&
                            <div className="MediosDePago">
                                <div><PayPalElement amount={venta.total} onApprove={handlePaypalOnApprove} onCancel={handlePaypalOnCancel} ventaId={venta.id} /></div>
                                <div><img onClick={handleFlowPayment} width="400" alt="Flow" src="/logo-flow.svg" /></div>
                            </div>}
                        {procesoCompra === PROCESANDO_PAGO &&
                            <div>Procesando pago...</div>
                        }
                        {procesoCompra === PAGO_APROBADO &&
                            <div>Pago aprobado</div>
                        }

                    </div>
                </ModalContainer>
            }
        </>
    );
};



export default ComprasListComponent;