import React from "react";
import { useFetch } from "../hooks/useFetch";

export const ProductosContext = React.createContext();

export const ProductosProvider = ({ children }) => {
    const { data: productos, loading, error } = useFetch('v1/productos');

    return (
        <ProductosContext.Provider value={{ productos, loading, error }}>
            {children}
        </ProductosContext.Provider>
    );
};
