import React, { useContext, useState } from "react";
import '../styles/views/GrabarView.css';
import Demoteca from "../containers/DemotecaContainer.jsx";
// import { AudioTrack, AudioTracks } from "../components/AudioTrack.jsx";
import { ArrowLeftCicleFillIcon, MusicNoteListIcon, ShareIcon, SoundwaveIcon, ToggleOffIcon, ToggleOnIcon } from "../components/BootstrapIcons.jsx";
// import AudioElementComponent from "../components/AudioElementComponent.jsx";
import AudioGoDemosComponent from "../components/AudioGoDemosComponent.jsx";
import { useFetch } from "../hooks/useFetch.jsx";
import { DemotecaContext, DemotecaProvider } from "../contexts/DemotecaContext.js";
import DemoCardComponent from "../components/DemoCardComponent.jsx";
import { serviceFetch } from "../utils/serviceFetch.js";

const backendRoot = process.env.REACT_APP_BACKEND_ROOT;


const DemotecaView = ({ setActivity }) => {

    const [listar, setListar] = useState(false);
    const [main, setMain] = useState("demoteca");
    const [audioPath, setAudioPath] = useState(null);
    const [autoplay, setAutoplay] = useState(false);
    // eslint-disable-next-line
    const { loading, error, data } = useFetch("demoteca/candownload");

    const handleShare = (e) => {
        const base = window.location.host;

        if (navigator.share && data?.success === 1) {
            navigator.share({
                title: 'GoDemos',
                text: 'Mira mi perfil en GoDemos',
                url: `${base}/profile/${data.username}`
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else if (data?.success === 1) {
            window.open(`${base}/profile/${data.username}`, '_blank');
        } else {
            alert("No se puede compartir el perfil en este momento");
        }
    };

    return (
        <DemotecaProvider>
            {!listar && <div className="GrabarView">
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <h1>Graba tus demos</h1>
                    <label className="SwitchInput">
                        <input type="checkbox" checked={autoplay} onChange={(e) => setAutoplay(e.target.checked)} style={{ display: 'none' }} />
                        {autoplay ? <ToggleOnIcon style={{ color: 'green' }} /> : <ToggleOffIcon style={{ color: 'black' }} />}&nbsp;Reproducir automáticamente
                    </label>
                    {data?.success && <label onClick={handleShare} style={{ cursor: 'pointer', background: 'var(--green-6)', padding: '5px 10px', borderRadius: '10px', margin: '10px', display: 'inline-block', fontSize: '14px' }}><ShareIcon size="16" /> Compartir Perfil</label>}
                    <div style={{ cursor: 'pointer', background: 'var(--primary-6)', padding: '5px 10px', borderRadius: '10px', margin: '10px', display: 'inline-block', fontSize: '14px', color: 'white' }} onClick={e => { e.preventDefault(); setListar(true) }}>Ver Lista</div>
                    {main === "demoteca" && <Demoteca setMain={setMain} setAudioPath={setAudioPath} canRecord={true} />}
                    {main === "justsaved" && <div className="JustRecorded">
                        <img src="https://godemos.cl/static/desktop/assets/img/logo.webp" alt="logo" height={128} />
                        <p>¡Felicitaciones! tu demo ha quedado guardado con éxito</p>
                        <button onClick={e => {
                            // setMain("demoteca");
                            // setAudioPath(null);
                            window.location.reload();
                        }}>Volver a la Demoteca</button>
                    </div>}
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                {audioPath &&
                    // <AudioElementComponent autoplay={autoplay} audioTrack={`${audioPath}`} style={{width:'400px'}} />
                    <AudioGoDemosComponent src={`${audioPath}`} autoplay={autoplay} style={{ width: '400px', alignItems: 'center' }} downloadable={data?.success === 1} />
                }
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>}
            {listar && <DemosView setListar={setListar} />}
        </DemotecaProvider>
    );
};

const DemosView = ({ setListar = () => { } }) => {

    const { loading, data, error } = useContext(DemotecaContext);


    const filterGeneric = (e, i) => {
        const elems = document.querySelectorAll("#DemosList > .DemoElement:not(:first-child)");
        
        for(let elem of elems) {
            console.log(elem);
            if(elem.querySelector(`.DemoElement__configuracion > span:nth-child(${i})`).textContent.toLowerCase().includes(e.target.value.toLowerCase())) {
                elem.style.display = "";
            } else {
                elem.style.display = "none";
            }
        }

    };

    const filterEstilo = (e) => filterGeneric(e, 1);
    const filterEdad = (e) => filterGeneric(e, 2);
    const filterEmocion = (e) => filterGeneric(e, 3);

    const [demo, setDemo] = useState(null);

    return (<>
        <h1 style={{ display: "flex", alignItems: "center" }}>
            <ArrowLeftCicleFillIcon onClick={e => { e.preventDefault(); setListar(false) }} style={{ padding: "0px 20px", fontSize: "1em", cursor: "pointer" }} />
            Lista de Demos Grabados</h1>
        <div className="DemosView">
            {loading && <p>Cargando...</p>}
            {error && <p>Error: {error}</p>}
            {data && <>
                <div className="DemosList" id="DemosList">
                    <div className="DemoElement" onClick={e => { }}>
                        <span className="DemoElement__nombre">
                            <b>Demo</b>
                        </span>
                        <span className="DemoElement__configuracion filter-demo">
                            <span><input onChange={filterEstilo} placeholder="Estilo" /></span>
                            <span><input onChange={filterEdad} placeholder="Edad" /></span>
                            <span><input onChange={filterEmocion} placeholder="Emoción" /></span>
                        </span>
                    </div>
                    {data.collection.map((demo, index) => <DemoElement key={index} demo={demo} setDemo={setDemo} demoSelected={false} />)}
                </div>
                {demo && <DemoCardComponent demo={demo} />}
            </>}
        </div>
    </>
    );
};

const DemoElement = ({ demo, setDemo, demoSelected }) => {

    const { getTipo, getEdad, getEmocion } = useContext(DemotecaContext);
    const [publico, setPublico] = useState(demo.publico);

    const publicar = (value) => {
        serviceFetch(`${value ? 'publicar' : 'ocultar'}/audiolocutor/${demo.id}`)
            .then(response => response.json())
            .then(json => {
                if (json.success === 1) {
                    setPublico(value);
                }
            })
            .catch(error => console.error(error));
    };

    return (
        <div className="DemoElement" key={demo.id} onClick={e => { }}>
            <span className="DemoElement__nombre">
                {(demoSelected && demoSelected.id === demo.id) ?
                    <SoundwaveIcon size="24px" style={{ 'marginRight': '10px' }} />
                    :
                    <MusicNoteListIcon size="24px" style={{ 'marginRight': '10px' }} />
                }

                <div style={{ color: publico ? "var(--green-10)" : "var(--secondary-8)", display: "flex", alignItems: "center" }} >
                    &nbsp;{publico ? "Público" : "Privado"}&nbsp;
                    {publico && <ToggleOnIcon onClick={e => publicar(false)} />}
                    {!publico && <ToggleOffIcon onClick={e => publicar(true)} />}
                </div>
                <div style={{ margin: "10px 40px", zoom: "0.8" }}><AudioGoDemosComponent src={demo.path_mezclado} style={{ width: "300px" }} /></div>
            </span>
            <span className="DemoElement__configuracion">
                <span>{getTipo(demo.tipo)}</span>
                <span>{getEdad(demo.edad)}</span>
                <span>{getEmocion(demo.emocion)}</span>
            </span>
        </div>
    );
};

export default DemotecaView;