import React from "react";

// Import the useFetch hook from the hooks folder.
import { useFetch } from "../hooks/useFetch.jsx";

export const CustomerRelationManagerContext = React.createContext();

export const CustomerRelationManagerProvider = ({ children }) => {
    const { data: estadoResponse, loading: estadoLoading } = useFetch("get_estados");
    const { data: tiposResponse, loading: tiposLoading } = useFetch("get_tipos_crm");
    const { data: derechosResponse, loading: derechosLoading } = useFetch("get_derechos");
    const { data: tiempoDeUsoResponse, loading: tiempoDeUsoLoading } = useFetch("get_tiempo_de_uso");
    const { data: tiposDocumentoResponse, loading: tiposDocumentoLoading } = useFetch("get_tipos_documento");

    const { data: tiposClienteResponse, loading: tiposClienteLoading } = useFetch("get_tipos_cliente");
    const { data: paisesResponse, loading: paisesLoading } = useFetch("get_paises");
    const { data: estadosCliente, loading: estadosClienteLoading } = useFetch("get_estados_cliente");

    let loading = estadoLoading || tiposLoading || derechosLoading || tiempoDeUsoLoading || tiposDocumentoLoading || tiposClienteLoading || paisesLoading || estadosClienteLoading;

    const [refreshRegistros, setRefreshRegistros] = React.useState(0);
    const [refreshClientes, setRefreshClientes] = React.useState(0);

    return (
        <CustomerRelationManagerContext.Provider value={{
            estadoResponse,
            tiposResponse,
            derechosResponse,
            tiempoDeUsoResponse,
            tiposDocumentoResponse,
            tiposClienteResponse,
            paisesResponse,
            estadosCliente,
            loading,
            refreshRegistros,
            setRefreshRegistros,
            refreshClientes,
            setRefreshClientes
        }}>
            {children}
        </CustomerRelationManagerContext.Provider>
    );
}