import React from "react";
import { useFetch } from "../hooks/useFetch";

export const DemotecaContext = React.createContext();

export const DemotecaProvider = ({ children }) => {

    const [demo, setDemo] = React.useState(null);

    const { loading, data, error } = useFetch("demoteca_records/");
    const { data: config_response} = useFetch("demoteca_config/");

    const getTipo = (tipo_id) =>{
        if(!config_response) return null;
        return config_response.tipos.find(tipo => tipo.id === tipo_id).nombre;
    }

    const getEdad = (edad_id) =>{
        if(!config_response) return null;
        return config_response.edades.find(edad => edad.id === edad_id).nombre;
    }

    const getEmocion = (emocion_id) =>{
        if(!config_response) return null;
        return config_response.emociones.find(emocion => emocion.id === emocion_id).nombre;
    }

    return (
        <DemotecaContext.Provider value={{ demo, setDemo, loading, data, error, getEdad, getTipo, getEmocion  }}>
            {children}
        </DemotecaContext.Provider>
    );
};