import React from 'react';
import { useFetch } from '../hooks/useFetch';

export const VentasContext = React.createContext();
export const VentasProvider = ({ children }) => {
    const [refresh, setRefresh] = React.useState(0);
    const { data: ventas, loading, error } = useFetch('v1/ventas', {},[refresh]);
    const [venta, setVenta] = React.useState(null);

    const refreshVentas = () => {
        setRefresh(refresh + 1);
    };

    return (
        <VentasContext.Provider value={{ venta, ventas, loading, error, setVenta, refreshVentas }}>
            {children}
        </VentasContext.Provider>
    );
}


export const DetalleVentaContext = React.createContext();
export const DetalleVentaProvider = ({venta, children }) => {
    const { data: detalle, loading, error } = useFetch(`v1/detalles/?venta=${venta.id}`);
    return (
        <DetalleVentaContext.Provider value={{ detalle, loading, error }}>
            {children}
        </DetalleVentaContext.Provider>
    );
};