import React, { useContext, useEffect, useRef } from 'react';
import '../styles/views/WelcomeView.css';
import { useFetch } from "../hooks/useFetch.jsx"
import { BellFillIcon, CashCoinIcon, CheckCircleFillIcon, CloudUpload, EditIcon, MicIcon, PigBankIcon, TrashFillIcon } from '../components/BootstrapIcons.jsx';
import PieChart from '../components/PieChart.jsx';
import { serviceFetch, serviceHttpRequest } from '../utils/serviceFetch.js';
import AudioElementComponent from '../components/AudioElementComponent.jsx';
import ProfileContainer from '../containers/ProfileContainer.jsx';
import { Link } from 'react-router-dom';
import { ProfileContext, ProfileProvider } from '../contexts/ProfileContext.js';

const radio = 50;

const WelcomeView = ({ setActivity }) => {
  const [genero_voz, setGeneroVoz] = React.useState("M");
  return (
    <ProfileProvider>
      <div className='WelcomeView'>
        <ProfileContainer setGeneroVoz={setGeneroVoz} />
        <Estadistica genero_voz={genero_voz} setActivity={setActivity} />
        <div style={{ display: 'flex', width: '100%' }}>
          <MisDemos />
          <Notificaciones />
        </div>
      </div>
    </ProfileProvider>
  );
};

const EstadosRegistro = ({ estados, registros }) => {

  let cantidad_proyectos = 0;
  let ingresos_proyectos = 0;
  let estados_series = [];
  for (let i = 0; i < estados.length; i++) {
    // let count = registros.filter((registro) => registro.estado.id === estados[i].id).length;
    // count>0 && estados_series.push({ name: estados[i].nombre, value: count });
    let valor = 0;
    let cantidad = 0;
    for (let j = 0; j < registros.length; j++) {
      if (registros[j].estado.id === estados[i].id) {
        valor += registros[j].valor;
        cantidad++;
      }
    }
    estados_series.push({ name: estados[i].nombre, value: valor, cant: cantidad });
    cantidad_proyectos += cantidad;
    ingresos_proyectos += valor;
  }

  return (
    <div>
      <div><PieChart hideCategories={true} serie={estados_series} radio={radio} /></div>
      <p><PigBankIcon size="32px" /><br />Durante los últimos 30 días has tenido <b>{cantidad_proyectos}</b> proyectos activos, cuyos ingresos equivalen a <b>${ingresos_proyectos.toLocaleString()}</b> </p>
    </div>
  );
};

const PorcentajeAvance = ({ intencion_config, audio_locutor, genero_voz }) => {

  let serie = [];

  serie.push({ name: 'Faltan', value: (intencion_config - audio_locutor) });
  serie.push({ name: 'Grabados', value: audio_locutor });

  let colors = ["var(--secondary-7)", "var(--primary-8)"];

  return (
    <div>
      <div><PieChart serie={serie} radio={radio} colors={colors} /></div>
      <p><MicIcon size='32px' /><br />
        ¡Has completado un <b>{Math.floor(100 * audio_locutor / intencion_config)}%</b> de todas las intenciones de tu Demoteca! ¡Vamos que se puede!<br />La práctica hace {genero_voz === "F" ? "a la Maestra" : "al Maestro"}. Vamos por todos esos demos.
      </p>
    </div>
  );
};

const Estadistica = ({ genero_voz = "M", setActivity = e => {} }) => {
  let formData = new FormData();
  let today = new Date();
  let thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  formData.append("gt_fecha", thirtyDaysAgo.toISOString().split('T')[0]);
  // let csrftoken = "";

  // if (document.cookie) {
  //   let splitted = document.cookie.split("; ")
  //   let aux = splitted.find((row) => row.startsWith("csrfauxtoken="));
  //   csrftoken = aux.split("=")[1];
  // } 
  formData.append("csrfmiddlewaretoken", localStorage.getItem("csrf_token"));

  const { locutorPerfil } = useContext(ProfileContext);

  const { data: estadosRegistroResponse, error: estadosRegistroError, loading: estadosRegistroLoading } = useFetch('get_estados');
  const { data: registrosResponse, error: registrosError, loading: registrosLoading } = useFetch('get_registros_crm/id/desc', { method: "POST", body: formData });
  const { data: porcentajeAvanceResponse, error: porcentajeAvanceError, loading: porcentajeAvanceLoading } = useFetch('get_porcentaje_avance');

  let regLoading = estadosRegistroLoading || registrosLoading;

  return (
    <>
      <div className='Estadistica'>
        {porcentajeAvanceLoading && <div className='loading'>Cargando...</div>}
        {porcentajeAvanceError && <div className='error'>{porcentajeAvanceError}</div>}
        {!porcentajeAvanceLoading &&
          <PorcentajeAvance
            genero_voz={genero_voz}
            intencion_config={porcentajeAvanceResponse.intencion_config}
            audio_locutor={porcentajeAvanceResponse.audio_locutor} />}
        {locutorPerfil && locutorPerfil.plan === "pro" && (<>{regLoading && <div className='loading'>Cargando...</div>}
          {registrosError && <div className='error'>{registrosError}</div>}
          {estadosRegistroError && <div className='error'>{estadosRegistroError}</div>}
          {!regLoading && <EstadosRegistro estados={estadosRegistroResponse.estados} registros={registrosResponse.registros} />}</>)}
        {locutorPerfil && locutorPerfil.plan !== "pro" && <TarifarioBox setActivity={setActivity} />}
      </div>
    </>
  );
};

const TarifarioBox = ({ setActivity = () => { } }) => {

  return (
    <div className='TarifarioButton'>
      <div><Link to={"/tarifario"} onClick={e => setActivity("tarifario")}><CashCoinIcon size="72px" /></Link></div>
      <p>En GoDemos, entendemos la importancia de tener una tarifa justa y competitiva para garantizar una competencia equilibrada en la industria. Por eso, hemos recopilado las tarifas recomendadas por las principales asociaciones de locutores en Latinoamérica.</p>
    </div>
  );
};

const Notificaciones = () => {

  const { data, error, loading } = useFetch('get_notificaciones');
  const [notificacion, setNotificacion] = React.useState(null);

  const checkNotificacion = (notificacion) => {
    serviceFetch(`read_notificacion/${notificacion.id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error en la respuesta del servidor");
        }
        notificacion.leido = true;
      })
  };



  return (
    <div className='Notificaciones'>
      <h2>Notificaciones</h2>
      {loading && <div className='loading'>Cargando...</div>}
      {error && <div className='error'>{error}</div>}
      {data && (
        <div>
          <div className='Notificaciones-lista'>
            {data.notificaciones.map((element, index) => {
              let fecha = new Date(element.created).toLocaleString();
              return (
                <div key={index} className='Notificacion' onClick={() => { checkNotificacion(element); setNotificacion(element) }}>
                  <span>
                    {!element.leido && <BellFillIcon size='16px' />}
                  </span>
                  <span>
                    {notificacion && notificacion.id === element.id && <b>{element.asunto}</b>}
                    {(!notificacion || notificacion.id !== element.id) && <span>{element.asunto}</span>}
                  </span>
                  <br />
                  <small>{fecha}</small>
                </div>
              )
            })}
          </div>
          <div className='Notificaciones-vista'>
            {!notificacion && <div>Selecciona una notificación para ver su contenido</div>}
            {notificacion && (() => {
              let fecha = new Date(notificacion.created).toLocaleString();
              return (
                <div>
                  <div>
                    <h2>{notificacion.asunto}</h2>
                    <div>{fecha}</div>
                  </div>
                  <div>
                    <span style={{ whiteSpace: "pre-wrap" }}>{notificacion.mensaje}</span>
                  </div>
                </div>
              )
            })()}
          </div>
        </div>
      )}
    </div>
  );
};

const MisDemos = () => {
  const [reload, setReload] = React.useState(0);
  const { data, error, loading } = useFetch('get_mis_demos', {}, [reload]);
  const [fileObject, setFileObject] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);

  const fileInput = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files.length === 0) {
      setFileObject(null);
      return;
    }
    setFileObject(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubirDemo = () => {
    if (!fileObject) {
      return;
    }
    let blob = new Blob([fileInput.current.files[0]], { type: fileInput.current.type });

    setUploading(true);
    serviceHttpRequest('demo/upload', { method: "POST", body: blob },
      uploadedEvent => {
        setUploadProgress("Listo!");
      },
      uploadprogressEvent => {
        setUploadProgress(uploadprogressEvent.loaded / uploadprogressEvent.total);
      })
      .then((response) => {
        setUploading(false);
        if (response.success === 0) {
          alert(response.message);
          return;
        }
        setReload(reload + 1);
        setFileObject(null);
      });
  };

  return (
    <div className='MisDemos'>
      <h2>Mis Demos</h2>
      {loading && <div className='loading'>Cargando...</div>}
      {error && <div className='error'>{error}</div>}
      {data && (
        <div>
          <div className='MisDemos-lista'>
            {data.mis_demos.map((demo, index) => {
              return <DemoItem key={index} demo={demo} />
            })}
            {fileObject && <>
              <AudioElementComponent audioTrack={fileObject} />
              <button className='SubirDemo' onClick={handleSubirDemo}>Subir Demo</button>
              {uploading && <div className='loading'>Subiendo Audio ({Math.round(uploadProgress * 100)}%)...</div>}
            </>}
          </div>

          <div className='Demo'>
            <label><CloudUpload size='64px' /><input type='file' ref={fileInput} onChange={handleFileChange} multiple={false} /></label>
          </div>
        </div>
      )}
    </div>
  );
};

const DemoItem = ({ demo }) => {
  const [editing, setEditing] = React.useState(false);
  const [available, setAvailable] = React.useState(true);

  useEffect(() => {
    const input = document.getElementById(`demo-${demo.id}`);
    input.value = demo.nombre;
  }, [demo]);

  const handleEdit = (e) => {
    setEditing(true);
    document.getElementById(`demo-${demo.id}`).focus();
  };

  const handleUpdate = () => {
    const input = document.getElementById(`demo-${demo.id}`);
    const formData = new FormData();
    formData.append("nombre", input.value);
    serviceFetch(`demos/${demo.id}/update_demo_name`, "POST", formData)
      .then(r => r.json())
      .then(json => {
        if (json.success === 0) {
          alert(json.message);
          return;
        }
        setEditing(false);
      })
      .catch(e => {
        alert(e);
      });
  };

  const handleDelete = () => {
    if (!window.confirm("¿Confirmas que deseas eliminar este demo?")) {
      return;
    }

    serviceFetch(`demos/${demo.id}/delete_demo`, "POST")
      .then(r => r.json())
      .then(json => {
        if (json.success === 0) {
          alert(json.message);
          return;
        }
        setEditing(false);
        setAvailable(false);
      })
      .catch(e => {
        alert(e);
      });
  };

  return (
    <>
      {available && <div className='Demo'>
        <div>
          <input id={`demo-${demo.id}`} readOnly={!editing} />

          {!editing && <i><EditIcon size="14px" onClick={handleEdit} /></i>}
          {editing && <>
            <i onClick={handleUpdate}><CheckCircleFillIcon /></i>&nbsp;
            <i onClick={handleDelete}><TrashFillIcon size="16px" /></i>
          </>}

        </div>
        <AudioElementComponent audioTrack={demo.demo} />
        <span style={{ fontSize: '0.75em', marginTop: '10px' }}>{new Date(demo.created).toLocaleString()}</span>
      </div>}
    </>
  );
}




export default WelcomeView;