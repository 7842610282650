import React from "react";
import { useFetch } from "../hooks/useFetch";
import { CloseIcon } from "../components/BootstrapIcons";
import '../styles/containers/MedioDePagoContainer.css';

const MedioDePagoContainer = ({ handleClose = e => { } }) => {

    const { data: medioDePago, error, loading } = useFetch('get_detalle_medio_de_pago');

    return (
        <div className="MedioDePago">
            <div>
                <CloseIcon
                    onClick={handleClose}
                    style={{
                        cursor: "pointer",
                    }}
                />
            </div>
            <h1>Suscripción</h1>
            {loading && <div className='loading'>Cargando...</div>}
            {error && <div className='error'>{error}</div>}
            {medioDePago && medioDePago.success === 1 && (
                <div>
                    {medioDePago.subId && <div>
                        <h3>ID Paypal</h3>
                        <div>{medioDePago.subId}</div>
                    </div>}

                    {medioDePago.flowSubId && <div>
                        <h3>ID Flow</h3>
                        <div>{medioDePago.flowSubId}</div>
                    </div>}
                    <div>
                        <h3>Plan</h3>
                        <div>{medioDePago.plan}</div>
                    </div>
                    <div>
                        <h3>Estado</h3>
                        <div>{medioDePago.status}</div>
                    </div>
                    <div>
                        <h3>Monto Pagado</h3>
                        <div>{medioDePago.monto_pagado} {medioDePago.moneda ? medioDePago.moneda : ''}</div>
                    </div>
                    <div>
                        <h3>Fecha de Pago</h3>
                        <div>{new Date(medioDePago.start_time).toLocaleString()}</div>
                    </div>
                    <div>
                        <h3>Fecha de Próximo Pago</h3>
                        <div>{new Date(medioDePago.next_billing_time).toLocaleString()}</div>
                    </div>
                </div>
            )}
            {medioDePago && medioDePago.success === 0 && (
                <div>
                    <div>{medioDePago.message}</div>
                </div>
            )}
        </div>
    );
};

export default MedioDePagoContainer;