import React, { useEffect, useState } from "react";
import '../styles/components/AudioTrackComponent.css';
import { PlayIcon, PauseIcon, VolumeUpIcon, VolumeDownIcon } from "./BootstrapIcons";

const backendRoot = process.env.REACT_APP_BACKEND_ROOT;

const AudioElementComponent = ({ audioTrack, style = {} }) => {
    // console.debug("AudioElementComponent", audioTrack);
    let audioPath = String(audioTrack).startsWith("blob") ? audioTrack : (audioTrack.startsWith("http") ? `${audioTrack}`:(audioTrack.startsWith("/")?`${backendRoot}${audioTrack}`:`${backendRoot}/${audioTrack}`));
    console.info("AudioElementComponent", audioPath);
    // eslint-disable-next-line
    const audio = new Audio(audioPath);

    useEffect(() => {
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, [audio]);


    return (
        <div className="AudioTrackComponent" style={style}>
            <AudioTrackCompontentBar audio={audio} />
        </div>
    );
};

const AudioTrackCompontentBar = ({ audio }) => {

    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(1);
    const [playing, setPlaying] = useState(false);
    const [metadataloaded, setMetadataLoaded] = useState(false);

    useEffect(() => {
        return () => {
            !audio.paused && audio.pause();
            setPlaying(false);
            setCurrentTime(0);
            setDuration(1);
        };
    }, [audio]);

    audio.addEventListener("loadedmetadata", () => {
        setDuration(audio.duration);
        setMetadataLoaded(true);
    });

    audio.addEventListener("timeupdate", () => {
        setCurrentTime(audio.currentTime * 100 / audio.duration);
    });

    audio.addEventListener("ended", () => {
        setPlaying(false);
        audio.currentTime = 0;
        setCurrentTime(0);
    });

    audio.addEventListener("play", () => {
        setPlaying(true);
    });

    audio.addEventListener("pause", () => {
        setPlaying(false);
    });

    const handlePlayClick = () => {
        audio.play();
    };

    const handlePauseClick = () => {
        audio.pause();
    };

    const handleRangeChange = (e) => {
        audio.currentTime = e.target.value * audio.duration / 100;
        setCurrentTime(e.target.value);
    };

    const formatTime = (time) => {
        let minutes = Math.floor(time / 60);
        let seconds = Math.floor(time % 60);
        // return it with format mm:ss
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const volUp = () => {
        if (audio.volume > 0.9) audio.volume = 1;
        else audio.volume = audio.volume + 0.1;
    };

    const volDown = () => {
        if (audio.volume < 0.1) audio.volume = 0;
        else audio.volume = audio.volume - 0.1;
    };

    return (
        <>
            <div className="AudioTrackComponent__bar">
                <span>{formatTime(audio.currentTime)}</span>
                <input type="range" min="0" max="100" value={currentTime} className="AudioTrackComponent__slider" onChange={handleRangeChange} />
                <span>{formatTime(duration)}</span>
            </div>
            <div className="AudioTrackComponent__controls">
                {metadataloaded && <>
                    <VolumeDownIcon size="24px" onClick={volDown} />
                    {!playing && <PlayIcon size="24px" onClick={handlePlayClick} />}
                    {playing && <PauseIcon size="24px" onClick={handlePauseClick} />}
                    <VolumeUpIcon size="24px" onClick={volUp} />
                </>}
                {!metadataloaded && <span>Cargando...</span>}
            </div>
        </>
    );
};

export default AudioElementComponent;