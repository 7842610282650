import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import LoginView from "../pages/LoginView";
import { serviceFetch } from "../utils/serviceFetch";

const CheckLogin = ({ setActivity, setIsAuth, goto="welcome" }) => {

    const [message, setMessage] = useState(null);

    const useHandleOnSubmit = (event) => {
        event.preventDefault();
        setActivity("loading");

        let formData = new FormData(event.target);
        const url = "signin/";
        serviceFetch(url, "POST", formData)
            .then((res) => {
                if (res.status === 401)
                    throw new Error("Usuario o contraseña incorrectos");
                return res.json();
            })
            .then((data) => {
                setIsAuth(true);
                localStorage.setItem("csrf_token", data.csrf_token);
                setActivity(goto);
            })
            .catch((error) => {
                console.log(error);
                setActivity(null);
                setMessage(error.message);
            })
            .finally(() => {
                console.log("finally");
            });
    };

    const handleSignup = event => {

        const usernameElement = document.getElementById('username');
        const passwordElement = document.getElementById('password');

        setActivity("loading");

        let formData = new FormData();
        formData.append("username", usernameElement.value);
        formData.append("password", passwordElement.value);
        formData.append("password_confirm", passwordElement.value);

        const url = "signup/";
        serviceFetch(url, "POST", formData)
            .then((res) => {
                if (res.status === 401)
                    throw new Error("Usuario o contraseña incorrectos");
                return res.json();
            })
            .then((data) => {
                if(!data.success){
                    alert(data.message);
                    setActivity(null);
                    return;
                }
                setIsAuth(true);
                localStorage.setItem("csrf_token", data.csrf_token);
                setActivity(goto);
            })
            .catch((error) => {
                console.log(error);
                setActivity(null);
            })
            .finally(() => {
                console.log("finally");
            });
    };

    const { data, loading, error, httpStatus } = useFetch("check_login/", {'method':'POST'});

    if (httpStatus && httpStatus === 200 && data) {
        setIsAuth(true);
        if(data.csrf_token){
            localStorage.setItem("csrf_token", data.csrf_token);
        }
        return setActivity(goto);
    }
    return (
        <div className="CheckLogin">
            {loading && <p>Loading...</p>}
            {message && <div className="CheckLogin-mensaje">{message}</div>}
            {httpStatus && httpStatus === 401 && <LoginView setMessage={setMessage} handleOnSubmit={useHandleOnSubmit} handleSignup={handleSignup} />}
            {httpStatus && httpStatus === 200 && <p>Estás Logeado</p>}
            {error && <p>Error: {error.message}</p>}
        </div>
    );
}

export default CheckLogin;