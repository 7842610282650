import React from "react";
import { useFetch } from "../hooks/useFetch";

export const ProfileContext = React.createContext();

export const ProfileProvider = ({ children }) => {
    
    const { data: locutorPerfil, error, loading } = useFetch('get_perfil');
    const { data: paisesResponse, error: paisesError, loading: paisesLoading } = useFetch('get_paises');
    const { data: acentosResponse, error: acentosError, loading: acentosLoading } = useFetch('get_acentos');

    return (
        <ProfileContext.Provider value={{ locutorPerfil, error, loading, paisesResponse, paisesError, paisesLoading, acentosResponse, acentosError, acentosLoading }}>
            {children}
        </ProfileContext.Provider>
    );
};