import React from "react";
import "../styles/ModalContainer.css";
import { CloseIcon } from "../components/BootstrapIcons";



const ModalContainer = ({ setDisplay, display, children, color = 'white' }) => {
    const closeModal = () => {
        setDisplay(false);
    };

    const closeStyle = {
        color: color,
        padding: '5px',
        position: 'absolute',
        right: '15px',
        top: '15px',
    };

    return (
        <div className="ModalContainer" style={{ 'display': display ? '' : 'none' }}>
            <div className="ModalContainer__content">
                <div style={closeStyle}>
                    <CloseIcon onClick={closeModal} style={{ 'cursor': 'pointer' }} />
                </div>
                {children}
            </div>
        </div>
    );
};

export default ModalContainer;