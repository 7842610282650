import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import "../styles/Demoteca.css";
import { MicIcon } from "../components/BootstrapIcons.jsx";
import ModalContainer from './ModalContainer.jsx';
// import { AudioTrack, AudioTracks, RecordTrack } from "./AudioTrack";
import DemotecaRecord from "./DemotecaRecordContainer.jsx";

const Demoteca = ({ setMain, setAudioPath, canRecord = false }) => {


    const { data: config_response, loading: config_loading, error: config_error } = useFetch("demoteca_config/");
    const { data: records_response, loading: records_loading, error: records_error } = useFetch("demoteca_records/",{
        method: "POST",
        body: null
    });

    const [tipoSelected, setTipoSelected] = useState(0);
    const [edadSelected, setEdadSelected] = useState(0);
    const [emocionSelected, setEmocionSelected] = useState(0);

    const [showRecordModal, setShowRecordModal] = useState(false);

    if (config_loading || records_loading) return <div className="loading">Cargando...</div>;
    if (config_error || records_error) return <div className="error">Error:</div>;

    const selectTipo = (tipo) => {
        if (tipo === tipoSelected) {
            setTipoSelected(0);
        } else {
            setTipoSelected(tipo);
        }
    };

    const selectEdad = (edad) => {
        if (edad === edadSelected) {
            setEdadSelected(0);
        } else {
            setEdadSelected(edad);
        }
    };

    const selectEmocion = (emocion) => {
        if (emocion === emocionSelected) {
            setEmocionSelected(0);
        } else {
            setEmocionSelected(emocion);
        }
    };

    let audioLocutorCollection = records_response.collection;
    let audioLocutorAvailableCollection = updateAudioLocutorAvailable(audioLocutorCollection, tipoSelected, edadSelected, emocionSelected);

    let tipoCollection = getTipoCollection(config_response.tipos, audioLocutorAvailableCollection);
    let edadCollection = getEdadCollection(config_response.edades, audioLocutorAvailableCollection);
    let emocionCollection = getEmocionCollection(config_response.emociones, audioLocutorAvailableCollection);

    let audioLocutor = null;

    if (tipoSelected !== 0 && edadSelected !== 0 && emocionSelected !== 0) {
        if (audioLocutorAvailableCollection.length > 0) {
            audioLocutor = audioLocutorAvailableCollection[0];
            setAudioPath(`${audioLocutor.path_mezclado}`);
        } else {
            setAudioPath(null);
        }
    } else {
        setAudioPath(null);
    }

    return (
        <>
            <div className="Demoteca">
                <DemotecaItemlist collection={tipoCollection} selected={tipoSelected} title="Estilo" setSelected={selectTipo} />
                <DemotecaItemlist collection={edadCollection} selected={edadSelected} title="Edad" setSelected={selectEdad} />
                <DemotecaItemlist collection={emocionCollection} selected={emocionSelected} title="Emoción" setSelected={selectEmocion} />
                {(canRecord && tipoSelected !== 0 && edadSelected !== 0 && emocionSelected !== 0) &&
                    <>
                        <div className="Demoteca_audio">
                            {audioLocutor ?
                                <>
                                    <p>Puedes escuchar el demo o lo puedes grabar nuevamente.</p>
                                    <div>
                                        <button className="Demoteca-grabar-demo" onClick={() => setShowRecordModal(true)}><MicIcon /> Grabar Nuevamente</button>
                                    </div>
                                </>
                                :
                                <>
                                    <p>Aún no has grabado este demo.</p>
                                    <div>
                                        <button className="Demoteca-grabar-demo" onClick={() => setShowRecordModal(true)}><MicIcon /> Grabar Demo</button>
                                    </div>
                                </>
                            }

                        </div>
                        {showRecordModal &&
                            <ModalContainer display={showRecordModal} setDisplay={setShowRecordModal} >
                                <DemotecaRecord 
                                    tipo={tipoSelected} 
                                    edad={edadSelected}
                                    emocion={emocionSelected}
                                    setMain={setMain}
                                    setAudioPath={setAudioPath}
                                />
                            </ModalContainer>}
                    </>
                }
            </div>
        </>
    );
};

const DemotecaItemlist = ({ collection, selected, title, setSelected }) => {
    return (
        <div className="Demoteca_itemlist">
            <h1>{title}</h1>
            {collection.map((item, index) => (
                <div key={index}
                    className={"Demoteca_item" + (selected === item.id ? " selected" : "") + (item.available ? "" : " unavailable")}
                    onClick={() => setSelected(item.id)}
                >{item.nombre}</div>
            ))}
        </div>
    );
};

const getTipoCollection = (tipos, audioLocutorCollection) => {
    let tipoCollection = [];
    for (let tipo of tipos) {
        tipo.available = false;
        if (audioLocutorCollection.find((audioLocutor) => audioLocutor.tipo === tipo.id)) {
            tipo.available = true;
        }
        tipoCollection.push(tipo);
    }
    return tipoCollection;
};

const getEdadCollection = (edades, audioLocutorCollection) => {
    let edadCollection = [];
    for (let edad of edades) {
        edad.available = false;
        if (audioLocutorCollection.find((audioLocutor) => audioLocutor.edad === edad.id)) {
            edad.available = true;
        }
        edadCollection.push(edad);
    }
    return edadCollection;
};

const getEmocionCollection = (emociones, audioLocutorCollection) => {
    let emocionCollection = [];
    for (let emocion of emociones) {
        emocion.available = false;
        if (audioLocutorCollection.find((audioLocutor) => audioLocutor.emocion === emocion.id)) {
            emocion.available = true;
        }
        emocionCollection.push(emocion);
    }
    return emocionCollection;
};

const updateAudioLocutorAvailable = (audioLocutorCollection, tipoSelected, edadSelected, emocionSelected) => {
    let audioLocutorAvailableCollection = [];
    for (let audioLocutor of audioLocutorCollection) {
        if (tipoSelected === 0 && edadSelected === 0 && emocionSelected === 0) {
            audioLocutorAvailableCollection.push(audioLocutor);
        } else if (tipoSelected !== 0 && edadSelected === 0 && emocionSelected === 0) {
            if (tipoSelected === audioLocutor.tipo) {
                audioLocutorAvailableCollection.push(audioLocutor);
            }
        } else if (tipoSelected === 0 && edadSelected !== 0 && emocionSelected === 0) {
            if (edadSelected === audioLocutor.edad) {
                audioLocutorAvailableCollection.push(audioLocutor);
            }
        } else if (tipoSelected === 0 && edadSelected === 0 && emocionSelected !== 0) {
            if (emocionSelected === audioLocutor.emocion) {
                audioLocutorAvailableCollection.push(audioLocutor);
            }
        } else if (tipoSelected !== 0 && edadSelected !== 0 && emocionSelected === 0) {
            if (tipoSelected === audioLocutor.tipo && edadSelected === audioLocutor.edad) {
                audioLocutorAvailableCollection.push(audioLocutor);
            }
        } else if (tipoSelected !== 0 && edadSelected === 0 && emocionSelected !== 0) {
            if (tipoSelected === audioLocutor.tipo && emocionSelected === audioLocutor.emocion) {
                audioLocutorAvailableCollection.push(audioLocutor);
            }
        } else if (tipoSelected === 0 && edadSelected !== 0 && emocionSelected !== 0) {
            if (edadSelected === audioLocutor.edad && emocionSelected === audioLocutor.emocion) {
                audioLocutorAvailableCollection.push(audioLocutor);
            }
        } else if (tipoSelected !== 0 && edadSelected !== 0 && emocionSelected !== 0) {
            if (tipoSelected === audioLocutor.tipo && edadSelected === audioLocutor.edad && emocionSelected === audioLocutor.emocion) {
                audioLocutorAvailableCollection.push(audioLocutor);
            }
        }
    }
    return audioLocutorAvailableCollection;
};

export default Demoteca;