import React, { useContext, useRef } from "react";
import { CloseIcon, EnvelopeExclamationIcon, PathCheckIcon } from "../components/BootstrapIcons";
import { ChoicesLiveUpdate, InputLiveUpdate, SelectLiveUpdate } from "../components/LiveUpdateFields";
import { serviceFetch } from "../utils/serviceFetch";
import MedioDePagoContainer from "./MedioDePagoContainer";
import { ProfileContext } from "../contexts/ProfileContext";


const backendRoot = process.env.REACT_APP_BACKEND_ROOT;

const ProfileContainer = ({ setGeneroVoz = (voz) => { } }) => {

  const ProfileEdit = useRef(null);
  const avatar = useRef(null);
  const avatarEdit = useRef(null);
  const [show_medio_de_pago, setShowMedioDePago] = React.useState(false);


  const { locutorPerfil, error, loading, paisesResponse, paisesError, paisesLoading, acentosResponse, acentosError, acentosLoading } = useContext(ProfileContext);

  const [refresh, setRefresh] = React.useState(0);

  const verifiedStyle = {
    color: 'var(--green-9)'
  };
  const unVerifiedStyle = {
    color: 'var(--secondary-7)',
    cursor: 'pointer'
  };

  return (
    <div className='Profile'>
      {loading && <div className='loading'>Loading...</div>}
      {error && <div className='error'>{error}</div>}
      {locutorPerfil && (
        <>
          {setGeneroVoz(locutorPerfil.voz)}
          <div className='Profile-view'>
            <div>
              {locutorPerfil.verificado && <span><PathCheckIcon size="16" style={verifiedStyle} /></span>}
              &nbsp;{locutorPerfil.username}
              <h1>{locutorPerfil.nombre}</h1>
              <span style={{ whiteSpace: "pre-wrap" }}>{locutorPerfil.descripcion}</span>
              <div><a target='_blank' rel="noreferrer" href={locutorPerfil.links ? locutorPerfil.links : "https://godemos.cl"}>{locutorPerfil.links}</a></div>
              <div className="plan-flex"><span onClick={e => {/*setShowMedioDePago(!show_medio_de_pago)*/}}>{locutorPerfil.plan}</span></div>
            </div>
            <img
              ref={avatar}
              src={`${backendRoot}/${locutorPerfil.avatar}`}
              alt='avatar' onClick={(e) => { document.getElementById("Profile-edit").style.display = "" }}
              style={{
                clipPath: 'circle()',
                width: '180px',
                marginLeft: "20px",
                cursor: "pointer",
                objectFit: "cover"
              }} />
          </div>

          <div className='Profile-edit' ref={ProfileEdit} id="Profile-edit" style={{ display: "none" }}>
            <div>
              <CloseIcon
                onClick={() => {
                  setRefresh(refresh + 1);
                  ProfileEdit.current.style.display = "none";
                }}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            <div style={{ textAlign: 'right' }}>
              <div>{locutorPerfil.username}</div>

              {locutorPerfil.verificado && <span style={verifiedStyle}><PathCheckIcon size="16" /> correo verificado</span>}
              {!locutorPerfil.verificado && <span style={unVerifiedStyle} onClick={(e) => {
                serviceFetch(`confirmar_registro/${locutorPerfil.username}`, "GET")
                  .then(r => r.json())
                  .then(data => {
                    if (data.status !== "success") {
                      alert(data.message);
                    } else {
                      alert("Te hemos enviado un correo para verificar tu cuenta");
                    }
                  })
                  .catch(e => {
                    console.error(e);
                    alert("Ha ocurrido un problema al verificar el correo");
                  });
              }}><EnvelopeExclamationIcon size="16" /> verificar correo</span>}
              <br/><a target='_black' href={`/profile/${locutorPerfil.username}`}>ver perfil</a>
            </div>

            <label style={{ width: 'min-content' }}>
              <img
                ref={avatarEdit}
                src={`${backendRoot}/${locutorPerfil.avatar}`}
                alt='avatar'
                style={{
                  clipPath: 'circle()',
                  width: '192px',
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
              <input type="file" style={{ display: "none" }}
                onChange={e => {
                  if (e.target.files.length === 0) return;
                  avatarEdit.current.src = URL.createObjectURL(e.target.files[0]);
                  let formdata = new FormData();
                  formdata.append("avatar", e.target.files[0]);
                  serviceFetch('edit_perfil/avatar', "POST", formdata)
                    .then(r => r.json())
                    .then(data => {
                      avatarEdit.current.src = `${backendRoot}/${data.valor}`;
                      avatar.current.src = avatarEdit.current.src;
                    })
                    .catch(e => {
                      console.error(e);
                      alert("Ha ocurrido un problema al cambiar la imagen de perfil");
                      avatarEdit.current.src = avatar.current.src;
                    });
                }}
              />
            </label>
            <div>
              <span>Nombre</span>
              <div>
                <InputLiveUpdate type='text' value={locutorPerfil.nombre} url='edit_perfil/nombre' onUpdate={(v) => locutorPerfil.nombre = v} />
              </div>
            </div>
            <div>
              <span>Descripción</span>
              <div>
                <InputLiveUpdate type='text' value={locutorPerfil.descripcion} url='edit_perfil/descripcion' onUpdate={(v) => locutorPerfil.descripcion = v} style={{ height: '200px' }} />
              </div>
            </div>
            <div>
              <span>¿Tienes experiencia en locución?</span>
              <ChoicesLiveUpdate
                options={[{ id: 'N', nombre: 'No' }, { id: 'S', nombre: 'Sí' }]}
                url={"edit_perfil/experiencia"}
                value={locutorPerfil.experiencia}
                onUpdate={(v) => locutorPerfil.experiencia = v}
              />
            </div>
            <div>
              <span>Equipo de Grabación</span>
              <ChoicesLiveUpdate
                options={[
                  { id: 'Movil', nombre: 'Móvil' },
                  { id: 'Casa', nombre: 'Home Studio' },
                  { id: 'Estudio', nombre: 'Estudio Profesional' }
                ]}
                url={"edit_perfil/equipo"}
                value={locutorPerfil.equipo}
                onUpdate={(v) => locutorPerfil.equipo = v}
              />
            </div>
            <div>
              <span>Tiempo de Respuesta</span>
              <ChoicesLiveUpdate
                options={[
                  { id: 'Horas', nombre: 'Horas' },
                  { id: '24 horas', nombre: '24 Horas' },
                  { id: 'Días', nombre: 'Días' }
                ]}
                url={"edit_perfil/tiempo_respuesta"}
                value={locutorPerfil.tiempo_respuesta}
                onUpdate={(v) => locutorPerfil.tiempo_respuesta = v}
              />
            </div>
            <div>
              <span>País</span>
              {paisesLoading && <div className='loading'>Cargando países...</div>}
              {paisesError && <div className='error'>{paisesError}</div>}
              {paisesResponse && (
                <SelectLiveUpdate
                  options={paisesResponse.paises}
                  url={"edit_perfil/pais"}
                  value={locutorPerfil.pais?.id}
                  onUpdate={(v) => locutorPerfil.pais = v}
                />
              )}
            </div>
            <div>
              <span>Acento Nativo</span>
              {acentosLoading && <div className='loading'>Cargando acentos...</div>}
              {acentosError && <div className='error'>{acentosError}</div>}
              {acentosResponse && (
                <SelectLiveUpdate
                  options={acentosResponse.acentos}
                  url={"edit_perfil/acento"}
                  value={locutorPerfil.acento?.id}
                  onUpdate={(v) => locutorPerfil.acento = v}
                />
              )}
            </div>
            <div>
              <span>Género de tu Voz</span>
              <ChoicesLiveUpdate
                options={[
                  { id: 'M', nombre: 'Masculina' },
                  { id: 'F', nombre: 'Femenina' },
                ]}
                url={"edit_perfil/voz"}
                value={locutorPerfil.voz}
                onUpdate={(v) => locutorPerfil.voz = v}
              />
            </div>
            <div>
              <span>Página web</span>
              <small> (copie y pegue la URL de su sitio)</small>
              <div>
                <InputLiveUpdate type='text' value={locutorPerfil.links} url='edit_perfil/links' onUpdate={(v) => locutorPerfil.links = v} />
              </div>
            </div>
          </div>

          {show_medio_de_pago && <MedioDePagoContainer handleClose={e => setShowMedioDePago(false)} />}
        </>
      )}
    </div>
  );
};

export default ProfileContainer;