import React, { useContext, useState } from "react";
import Clients from "../containers/ClientsContainer.jsx";
import Dashboard from "../containers/DashboardContainer.jsx";
import Fechas from "../containers/FechasContainer.jsx";
import ProjectsContainer from "../containers/ProjectsContainer.jsx";
// import { useFetch } from "../hooks/useFetch.jsx";
import "../styles/CustomerRelationManager.css";
import { CustomerRelationManagerContext, CustomerRelationManagerProvider } from "../contexts/CustomerRelationManagerContext.js";

/**
 * CustomerRelationManager component.
 * 
 * This component is responsible for managing the customer relations.
 * It fetches data from various APIs and renders different views based on the selected view.
 * 
 * @returns {JSX.Element} The rendered CustomerRelationManager component.
 */
const CustomerRelationManager = () => {


    // const { data: estadoResponse, loading: estadoLoading } = useFetch("get_estados");
    // const { data: tiposResponse, loading: tiposLoading } = useFetch("get_tipos_crm");
    // const { data: derechosResponse, loading: derechosLoading } = useFetch("get_derechos");
    // const { data: tiempoDeUsoResponse, loading: tiempoDeUsoLoading } = useFetch("get_tiempo_de_uso");
    // const { data: tiposDocumentoResponse, loading: tiposDocumentoLoading } = useFetch("get_tipos_documento");


    // const { data: tiposClienteResponse, loading: tiposClienteLoading } = useFetch("get_tipos_cliente");
    // const { data: paisesResponse, loading: paisesLoading } = useFetch("get_paises");
    // const { data: estadosCliente, loading: estadosClienteLoading } = useFetch("get_estados_cliente");

    // let loading = estadoLoading || tiposLoading || derechosLoading || tiempoDeUsoLoading || tiposDocumentoLoading || tiposClienteLoading || paisesLoading || estadosClienteLoading;

    return (
        <CustomerRelationManagerProvider>
            <View />
        </CustomerRelationManagerProvider>
    );
};

const View = () => {

    const [view, setView] = useState("dashboard");

    const { loading } = useContext(CustomerRelationManagerContext);

    return <div className="CustomerRelationManager">
        {loading && <div className="loading">Loading...</div>}
        {!loading && <>
            <Menu setView={setView} />
            {view === "projects" && <ProjectsContainer />}
            {view === "clients" && <Clients />}
            {view === "dashboard" && <Dashboard />}
            {view === "fechas" && <Fechas />}
        </>}
    </div>;
};

const Menu = ({ setView }) => {
    return (
        <div className="Menu" >
            <span onClick={() => setView("projects")}>Projects</span>
            <span onClick={() => setView("clients")}>Clients</span>
            <span onClick={() => setView("dashboard")}>Dashboard</span>
            <span onClick={() => setView("fechas")}>Fechas</span>
        </div>
    );
};

export default CustomerRelationManager;