import React, { useEffect } from "react";

const PayPalElement = ({ plan_id = null, amount = 0, onApprove = (data,actions) => {}, onCancel = (data)=>{}, ventaId = null }) => {

  const uniqueid = `paypal-button-container-${Math.random().toString(36).substr(2, 9)}`;

  useEffect(() => {
    if (plan_id == null) {
      window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay'
        },
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: amount
              },
              invoice_id: ventaId
            }]
          });
        },
        onApprove: onApprove,
        onCancel: onCancel
      }).render(`#paypal-button-container-${uniqueid}`);
    } else {
      window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: function (data, actions) {
          return actions.subscription.create({
            plan_id: plan_id
          });
        },
        onApprove: onApprove,
        onCancel: onCancel
      }).render(`#paypal-button-container-${uniqueid}`);
    }
    // eslint-disable-next-line
  }, []);
  return (<div id={`paypal-button-container-${uniqueid}`}></div>);
};

export default PayPalElement;