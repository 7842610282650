import React from "react";
import PayPalElement from "./PayPalElement";
import ModalContainer from "../containers/ModalContainer";
import { ProductosContext } from "../contexts/ProductosContext";
import { Cart3Icon, CartPlusIcon, CloseIcon, DashCircleIcon, PlusCircleIcon } from "./BootstrapIcons";
import { CompraContext } from "../contexts/CompraContext";
import { serviceFetch, serviceHttpRequest } from "../utils/serviceFetch";
import { VentasContext } from "../contexts/VentasContext";
import MedioDePagoContainer from "../containers/MedioDePagoContainer";

const ProductosListComponent = () => {
    const { productos, loading, error } = React.useContext(ProductosContext);
    const [showActiveSub, setShowActiveSub] = React.useState(false);

    const compraContext = React.useContext(CompraContext);
    const [sub, setSub] = React.useState(null);

    const comprarSub = (producto) => {
        setSub(producto);
    };

    const checkDescuento = () => {
        const descuento = document.getElementById("descuento").value;
        if (descuento) {
            serviceFetch(`v1/descuento/check/${descuento}`)
                .then(r => r.json())
                .then(json => {
                    console.log(json);
                    if (json.available) {
                        window.location.href = `/descuento/${descuento}`;
                    }else{
                        alert(json.message);
                    }
                    
                });
        }
    };

    const addProducto = (id, producto) => {
        compraContext.addProducto(producto);
        const e = document.getElementById(id);
        e.style.animation = "greenbright 0.5s";
        setTimeout(() => {
            e.style.animation = "";
        }, 500);

    };

    return (
        <>
            <h1>Productos {compraContext.detalle.length > 0 && <button className="ver-compra" onClick={e => compraContext.setShow(true)}><Cart3Icon /> Ver Compra ({compraContext.detalle.reduce((acc, item) => acc + item.cantidad, 0)})</button>}</h1>
            <div className="ProductosList">
                {loading && <p>Cargando productos...</p>}
                {error && <p>Error...{console.log(error)}</p>}
                {productos &&
                    <>
                        <div>
                            <div><b>Nombre</b></div>
                            <div><b>Descripción</b></div>
                            <div><b>Precio</b></div>
                            <div><b>Moneda</b></div>
                        </div>
                        {productos.filter(p => p.tipo_pago === "unico").map((producto) => (
                            <div key={producto.id} id={`producto-${producto.id}`}>
                                <div>{producto.nombre}</div>
                                <div>{producto.descripcion}</div>
                                <div>{producto.precio?.toLocaleString()}</div>
                                <div>{producto.moneda?.nombre}</div>
                                <div><i onClick={e => addProducto(`producto-${producto.id}`, producto)}><CartPlusIcon size="28px" /></i></div>
                            </div>
                        ))}
                    </>
                }
            </div>
            <h1>Suscripciones</h1>
            <div className="ProductosList">
                {loading && <p>Cargando suscripciones...</p>}
                {error && <p>Error...{console.log(error)}</p>}
                {productos && productos.filter(p => p.tipo_pago !== "unico").length === 0 && <>
                    <p>Ya tienes un plan contratado{/*, <a href="#sub" onClick={e => setShowActiveSub(true)}>haz click aquí para ver el detalle</a>*/}. Si deseas cambiar tu tipo de suscripción, contáctate con nuestro equipo de soporte.</p>

                </>}
                {productos && productos.filter(p => p.tipo_pago !== "unico").length > 0 &&
                    <>
                        <div>
                            <div><b>Nombre</b></div>
                            <div><b>Descripción</b></div>
                            <div><b>Precio</b></div>
                            <div><b>Moneda</b></div>
                        </div>
                        {productos.filter(p => p.tipo_pago !== "unico").map((producto) => (
                            <div key={producto.id}>
                                <div>{producto.nombre}</div>
                                <div>{producto.descripcion}</div>
                                <div>{producto.precio?.toLocaleString()}</div>
                                <div>{producto.moneda?.nombre}</div>
                                <div><button onClick={e => comprarSub(producto)}>Suscribirse</button></div>
                            </div>
                        ))}
                    </>}
            </div>
            <h2>¿Tienes un cupón de canje?</h2>
            <div className="AplicarCupon"><input type="text" id="descuento" /><button onClick={e => checkDescuento()}>Aplicar</button></div>
            {sub && <SuscripcionDetalle sub={sub} onClose={e => setSub(null)} onPaymentAccepted={e => setSub(null)} />}
            {compraContext.show && <CarroDeCompras />}
            {showActiveSub && <MedioDePagoContainer handleClose={e => setShowActiveSub(false)} />}
        </>
    );
};

const CarroDeCompras = () => {

    const { compra, detalle, removeProducto, addProducto, setShow, resetCompra } = React.useContext(CompraContext);
    const { refreshVentas, setVenta } = React.useContext(VentasContext);

    const handleConfirmar = e => {
        const body = {
            "fecha": new Date().toISOString(),
            "total": compra.total,
            "medio_de_pago": "manual",
            "estado": "pendiente",
            "glosa": "Compra de productos",
            "codigo": `C${new Date().getTime()}`,
            "detalles": detalle.map((item) => {
                return {
                    "producto": item.producto.id,
                    "cantidad": item.cantidad,
                    "precio": item.precio,
                    "moneda": item.moneda.id
                }
            }),
            "moneda": compra.moneda.id
        };

        serviceHttpRequest("v1/ventas/", { "method": "POST", "body": JSON.stringify(body), }, null, null, "application/json")
            .then(json => {
                console.log(json);
                setVenta(json);
                refreshVentas();
                setShow(false);
                resetCompra();
            });
    };

    const handleRemoveProducto = (producto) => {
        removeProducto(producto);
    };

    const handleAddProducto = (producto) => {
        addProducto(producto);
    };

    return (
        <div className="CompraDetalle">
            <div><i><CloseIcon onClick={e => setShow(false)} /></i></div>
            <h2>Detalle de compra</h2>
            <div>{compra.estado_name}</div>

            <div>Medio de Pago: {compra.medio_de_pago_name}</div>
            <div>{compra.fecha && new Date(compra.fecha).toLocaleString()}</div>
            <h3>Detalle</h3>
            <div className="CompraDetalleList">
                {detalle.map((item, index) => (
                    <div key={item.id}>
                        <div>{item.producto?.nombre}</div>
                        <div><PlusCircleIcon size="14px" onClick={e => handleAddProducto(item.producto)} />&nbsp;{item.cantidad}&nbsp;<DashCircleIcon size="14px" onClick={e => handleRemoveProducto(item.producto)} /></div>
                        <div>{(item.precio*item.cantidad).toLocaleString()}</div>
                        <div>{item.moneda.nombre}</div>
                    </div>
                ))}
                <div>
                    <div></div>
                    <div><b>Total</b></div>
                    <div>{compra.total?.toLocaleString()}</div>
                    <div>{compra.moneda?.nombre}</div>
                </div>
            </div>
            <div>{detalle.length > 0 && <button onClick={handleConfirmar}>Confirmar Compra</button>}</div>
        </div>
    );
}

const SuscripcionDetalle = ({ sub, onClose = () => { }, onPaymentAccepted = () => { } }) => {
    const { refreshVentas } = React.useContext(VentasContext);
    const [venta, setVenta] = React.useState(null);
    const [showPago, setShowPago] = React.useState(false);
    const [SELECCIONAR_PAGO, PROCESANDO_PAGO, PAGO_APROBADO] = [0, 1, 2];
    const [procesoCompra, setProcesoCompra] = React.useState(SELECCIONAR_PAGO);

    const handlePagar = e => {
        const body = {
            "fecha": new Date().toISOString(),
            "total": sub.precio,
            "medio_de_pago": "manual",
            "glosa": `Compra de suscripción ${sub.nombre}`,
            "estado": "por_confirmar",
            "codigo": `S${new Date().getTime()}`,
            "detalles": [{
                "producto": sub.id,
                "cantidad": 1,
                "precio": sub.precio,
                "moneda": sub.moneda.id
            }],
            "moneda": sub.moneda.id
        };

        serviceHttpRequest("v1/ventas/", { "method": "POST", "body": JSON.stringify(body), }, null, null, "application/json")
            .then(json => {
                console.log(json);
                refreshVentas();
                setShowPago(true);
                setVenta(json);
            });
    };

    const handlePaypalOnCancel = (data) => {
        setProcesoCompra(SELECCIONAR_PAGO);
    };

    const handlePaypalOnApprove = (data, actions) => {
        console.log("handlePaypalOnApprove", data);
        setProcesoCompra(PROCESANDO_PAGO);
        let pago = {
            fecha: new Date().toISOString(),
            monto: venta.total,
            json: JSON.stringify(data),
            texto: "Plan de Suscripción con Paypal",
            venta: venta.id,
            medio_de_pago: "paypal"
        };
        console.log("pago", pago);
        serviceFetch(`v1/pagos/checkout-order/${data['orderID']}/`)
            .then(r => r.json())
            .then(json => {
                console.log("checkout order", json);
                if (json.status === "APPROVED") {
                    serviceHttpRequest("v1/pagos/pago-paypal/", { "method": "POST", "body": JSON.stringify(pago) }, null, null, "application/json")
                        .then(json => {
                            alert("Pago aprobado");
                            window.location.reload();
                        });
                }
            })
            .error(e => {
                console.log(e);
                alert("El pago no ha podido ser verificado.");
                window.location.reload();
            });
    };

    const handleFlowPayment = () => {
        setProcesoCompra(PROCESANDO_PAGO);
        serviceFetch(`v1/ventas/suscripcion-flow/${venta.id}/`)
            .then(r => r.json())
            .then(json => {
                if (json.status === "ok") {
                    console.log(json);
                    alert("¡Se ha creado la suscripción!.\nEn cuanto se confirme el pago, su suscripción será dada de alta.");
                    window.location.reload();
                } else {
                    alert("Error al solicitar el pago");
                    console.log(json);
                    setProcesoCompra(SELECCIONAR_PAGO);
                }
            });
    };

    return (<>
        <div className="CompraDetalle">
            <div><i><CloseIcon onClick={onClose} /></i></div>
            <h2>Suscripción</h2>
            <div>{sub.descripcion}</div>

            <h3>Detalle</h3>
            <div className="CompraDetalleList">

                <div>
                    <div>{sub.nombre}</div>
                    <div>1</div>
                    <div>{sub.precio.toLocaleString()}</div>
                    <div>{sub.moneda.nombre}</div>
                </div>
                <div>
                    <div></div>
                    <div><b>Total</b></div>
                    <div>{sub.precio.toLocaleString()}</div>
                    <div>{sub.moneda.nombre}</div>
                </div>
            </div>
            <div><button onClick={handlePagar}>Pagar</button></div>
        </div>
        {showPago && <ModalContainer setDisplay={e => {
            setShowPago(false);
            setVenta(null);
            refreshVentas();
            serviceFetch(`v1/ventas/cancel-order/${venta.id}/`, "GET")
                .then(r => r.json())
                .then(json => {
                    refreshVentas();
                });
        }} display={true} color="black">
            <div className="CompraPago">
                <h2>Selecciona tu Medio de Pago</h2>
                <p>Aviso: Para asegurar el proceso de pago, es necesario que tu correo electrónico funcione correctamente.</p>
                {(procesoCompra === SELECCIONAR_PAGO || procesoCompra === PROCESANDO_PAGO) &&
                    <div className="MediosDePago" style={{ display: procesoCompra === PROCESANDO_PAGO ? 'none' : '' }}>
                        {sub.paypal_id && <div><PayPalElement plan_id={sub.paypal_id} onApprove={handlePaypalOnApprove} onCancel={handlePaypalOnCancel} /></div>}
                        {sub.flow_id && <div><img onClick={handleFlowPayment} width="400" alt="Flow" src="/logo-flow.svg" /></div>}
                    </div>}
                {procesoCompra === PROCESANDO_PAGO &&
                    <div>Procesando pago...</div>
                }
                {procesoCompra === PAGO_APROBADO &&
                    <div>Pago aprobado</div>
                }

            </div>
        </ModalContainer>}
    </>);
};

export default ProductosListComponent;