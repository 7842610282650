import React from "react";
import { chartColors } from "../contexts/BackendProps";

const PieChart = ({ radio = 100, serie = [{ 'name': 'test', 'value': 0 }], hideCategories = false, colors = chartColors, title = null }) => {
    let suma = 0;
    for (let i = 0; i < serie.length; i++) {
        suma += serie[i].value;
    }
    let perimetro = radio * 2 * Math.PI;
    let rotacion = 0;

    const key = `svgchart${Math.random()}`;


    return (
        <svg key={key} xmlns="http://www.w3.org/2000/svg" width={radio * 4} height={radio * 4} style={{ minWidth: radio * 4, minHeight: radio * 4 }}>
            <defs>
                <filter x="0" y="0" width="1" height="1" id="solid">
                    <feFlood floodColor="var(--secondary-8)" result="bg" />
                    <feMerge>
                        <feMergeNode in="bg" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            {title && <text x="5" y={radio * 4-5} style={{fontFamily: "monospace", fontSize: "12px"}}>{title}</text>}
            {serie.map((item, i) => {
                let valor = serie[i].value;
                let onClick = serie[i].onClick || (() => { });

                if (valor === 0) return <></>;
                let largo = 0.002 * perimetro + perimetro * valor / suma;
                let offset = largo - perimetro;

                return (
                    <circle
                        key={`${key}_${i}_circle`}
                        id={`${key}_${i}_circle`}
                        r={radio}
                        cx={radio * 2}
                        cy={radio * 2}
                        fill="none"
                        cursor="pointer"
                        stroke={colors[i % colors.length]}
                        strokeWidth={radio * 2}
                        strokeDasharray={perimetro}
                        strokeDashoffset={offset}
                        onMouseOver={(e) => {
                            e.target.style.filter = 'brightness(1.2)';
                            let text1 = document.getElementById(`${key}_${i}_text1`);
                            let text2 = document.getElementById(`${key}_${i}_text2`);

                            text1.style.display = '';
                            text2.style.display = '';
                        }}
                        onMouseOut={e => {
                            e.target.style.filter = 'brightness(1)';
                            if (hideCategories) {
                                let text1 = document.getElementById(`${key}_${i}_text1`);
                                let text2 = document.getElementById(`${key}_${i}_text2`);

                                text1.style.display = 'none';
                                text2.style.display = 'none';
                            }
                        }}
                        style={{ filter: 'brightness(1)', transformOrigin: `${radio * 2}px ${radio * 2}px`, transform: `rotate(${rotacion}rad)` }}
                        onClick={onClick}
                    >{rotacion -= 2 * Math.PI * valor / suma}</circle>
                );
            })}
            {rotacion = 0}
            {serie.map((item, i) => {
                let nombre = serie[i].name;
                let valor = serie[i].value;

                if (valor === 0) return <></>;
                return (<>
                    <text
                        key={`${key}_${i}_text1`}
                        id={`${key}_${i}_text1`}
                        style={{ fontFamily: 'Montserrat, Arial', fontSize: 12, display: hideCategories ? 'none' : '' }}
                        x={radio * 2 + radio * Math.cos(rotacion - Math.PI * valor / suma)}
                        y={radio * 2 + radio * Math.sin(rotacion - Math.PI * valor / suma)}
                        dominantBaseline="middle" textAnchor="middle"
                        fill="white"
                        onMouseOver={(e) => {
                            let circle = document.getElementById(`${key}_${i}_circle`);
                            if (hideCategories) {
                                let text1 = document.getElementById(`${key}_${i}_text1`);
                                let text2 = document.getElementById(`${key}_${i}_text2`);
                                text1.style.display = '';
                                text2.style.display = '';
                            }
                            circle.style.filter = 'brightness(1.2)';
                        }}
                        onMouseOut={e => {
                            let circle = document.getElementById(`${key}_${i}_circle`);
                            if (hideCategories) {
                                let text1 = document.getElementById(`${key}_${i}_text1`);
                                let text2 = document.getElementById(`${key}_${i}_text2`);
                                text1.style.display = 'none';
                                text2.style.display = 'none';
                            }
                            circle.style.filter = 'brightness(1)';
                        }}
                    >&nbsp;&nbsp;{nombre}&nbsp;&nbsp;</text>
                    <text
                        key={`${key}_${i}_text2`}
                        id={`${key}_${i}_text2`}
                        style={{ fontFamily: 'Montserrat, Arial', fontSize: 12, display: hideCategories ? 'none' : '' }}
                        x={radio * 2 + radio * Math.cos(rotacion - Math.PI * valor / suma)}
                        y={14 + radio * 2 + radio * Math.sin(rotacion - Math.PI * valor / suma)}
                        dominantBaseline="middle" textAnchor="middle"

                        fill="white"
                        onMouseOver={(e) => {
                            let circle = document.getElementById(`${key}_${i}_circle`);
                            if (hideCategories) {
                                let text1 = document.getElementById(`${key}_${i}_text1`);
                                let text2 = document.getElementById(`${key}_${i}_text2`);
                                text1.style.display = '';
                                text2.style.display = '';
                            }
                            circle.style.filter = 'brightness(1.2)';
                        }}
                        onMouseOut={e => {
                            let circle = document.getElementById(`${key}_${i}_circle`);
                            if (hideCategories) {
                                let text1 = document.getElementById(`${key}_${i}_text1`);
                                let text2 = document.getElementById(`${key}_${i}_text2`);
                                text1.style.display = 'none';
                                text2.style.display = 'none';
                            }
                            circle.style.filter = 'brightness(1)';
                        }}
                    > {valor.toLocaleString()} </text>
                    {rotacion -= 2 * Math.PI * valor / suma}
                </>
                );

            })}
        </svg>
    );
};

export default PieChart;