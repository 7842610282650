
import React from 'react';
import { CloseIcon, DownloadIcon, MusicNoteListIcon, SoundwaveIcon, TrashFillIcon } from './BootstrapIcons';
import { InputLiveUpdate } from './LiveUpdateFields';

import AudioGoDemosComponent from './AudioGoDemosComponent';
import { serviceFetch } from '../utils/serviceFetch';

const backendRoot = process.env.REACT_APP_BACKEND_ROOT;

const AudioListComponent = ({ collection, onerecord, setOnerecord }) => {

    const mapAudio = (item, index) => (
        <div key={index} onClick={e => setOnerecord(item)}>
            <div>
                {(onerecord && onerecord.id === item.id) ? <SoundwaveIcon /> : <MusicNoteListIcon />}
            </div>
            <div>
                <InputLiveUpdate
                    value={item.detalle}
                    placeholder="Ingresa una breve descripción del audio"
                    url={`onerecord/detalle/${item.id}`}
                />
            </div>
            <div>
                {new Date(item.created).toLocaleString()}
            </div>
            <div>
                <a download={"AudioGoDemos.mp3"} title="Descargar" rel="noreferrer" target="_blank" href={`${backendRoot}${item.path_mezclado}`}><DownloadIcon size="1em" /></a>
            </div>
        </div>
    );

    return (
        <div className="AudioListComponent">
            {collection.map(mapAudio)}
        </div>
    );
};

export const AudioComponent = ({ onerecord, locutor, setOnerecord, reload=0, setReload=e=>null }) => {

    const handleBorrar = e => {
        if(window.confirm("¿Confirmas que deseas borrar este audio?")) {
            console.log("Borrando...");
        }

        serviceFetch(`onerecord/delete/${onerecord.id}`,'POST')
            .then(response => response.json())
            .then(response => {
                alert(response.message);
                if(response.status === "success") {
                    setOnerecord(null);
                    setReload(reload+1);
                }
            })
            .catch(error => {
                console.log(error);
                alert(error.message);
            });
    };

    return (
        <div className="AudioComponent">
            <CloseIcon size="24" onClick={e => { setOnerecord(null) }} style={{ cursor: 'pointer' }} />
            <div><img alt="Perfil" src={`${backendRoot}${locutor.avatar}`} /></div>
            <div className="AudioComponent_perfil">
                <span><sub>username: </sub>{locutor.username}</span>
                <span><sub>país: </sub>{locutor.pais.nombre}</span>
                <span><sub>acento: </sub>{locutor.acento.nombre}</span>
            </div>
            <p>{onerecord.detalle}</p>
            <AudioGoDemosComponent src={onerecord.path_mezclado} autoplay={true} downloadable />
            <p>&nbsp;</p>
            <div><button className="AudiosView__borrar" onClick={handleBorrar}><TrashFillIcon size="16" />&nbsp;Borrar Audio</button></div>
        </div>
    );
};

export default AudioListComponent;