import React, { useContext } from "react";
import { SoundwaveIcon , MusicNoteListIcon } from "./BootstrapIcons";
import '../styles/components/DemoListComponent.css';
import { DemotecaContext } from "../contexts/DemotecaContext";

const DemoListComponent = ({ demos, setDemo , demoSelected }) => {
    return ( 
        <div className="DemosList">
            {demos.map((demo, index) => <DemoElement key={index} demo={demo} setDemo={setDemo} demoSelected={demoSelected} />)}
        </div>
    );
};

const DemoElement = ({ demo, setDemo , demoSelected }) => {

    const handleClick = () => {
        console.log("handleClick: ", demo.id);
        demoSelected = demo;
        setDemo(demo);
    };

    const { getTipo, getEdad, getEmocion } = useContext(DemotecaContext);

    return (
        <div className="DemoElement" key={demo.id} onClick={handleClick} style={(demoSelected && demoSelected.id===demo.id)?{'background-color':'rgba(255,255,255,0.5'}:{}}>
            <span className="DemoElement__nombre">
            {(demoSelected && demoSelected.id===demo.id)?
                <SoundwaveIcon size="24px" style={{'marginRight':'10px'}} />
            :
                <MusicNoteListIcon size="24px" style={{'marginRight':'10px'}} />
            }
                {demo.perfil}
            </span>
            <span className="DemoElement__configuracion">
                <span>{getTipo(demo.tipo)}</span>
                <span>{getEdad(demo.edad)}</span>
                <span>{getEmocion(demo.emocion)}</span>
            </span>
        </div>
    );
};

export default DemoListComponent;